@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}

:root {
  --blue: #0074c2;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000c25;
  overflow-x: hidden;
  box-sizing: border-box;
}

a {
  color: #fff;
  text-decoration: none;
}

p {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: normal;
  line-height: 1.8em;
}

h2 {
  font-size: 50px;
  margin: 0;
  position: relative;
  z-index: 99;
  font-weight: 700;
  letter-spacing: -4px;
  line-height: 1.2em;
  display: inline-block;
}

h3 {
  font-size: 30px;
  margin: 0;
  position: relative;
  z-index: 99;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 1.2em;
  display: inline-block;
}

.block {
  width: 100%;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.container {
  width: 100%;
  max-width: 1200px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 25px;
  position: absolute;
  top: 0;
  z-index: 99;
}

.header img {
  width: 120px;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.landingbackground-image {
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -7;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center;
}

.hero {
  display: flex;
  align-items: center;
  margin-top: 110px;
  padding-bottom: 50px;
  flex-direction: column;
  justify-content: center;
}

.hand {
  width: 300px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%);
}

h1 {
  font-size: 80px;
  margin: 0;
  position: relative;
  z-index: 99;
  font-weight: 700;
  letter-spacing: -4px;
  line-height: 1.2em;
  display: inline-block;
}

.hero h2 {
  /* position: absolute; */
  display: inline-block;
  color: #0074c2;
  color: #fff;
  /* left: 55px;
  top: 70px; */
  font-size: 45px;
  margin: 0;
}

.hero h2 sup {
  font-size: 25px;
  margin-left: 5px;
}

.hero img {
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
}

.hero img {
  animation: pulse 5s infinite;
}

.happy {
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  /* position: absolute;
  top: -60px;
  left: -50px;
  transform: rotate(-20deg); */
  margin: 0;
  line-height: 1em;
}

@keyframes pulse {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.gradient {
  width: 100%;
  height: 50px;
  background: rgb(27, 53, 103);
  background: -moz-linear-gradient(
    180deg,
    rgba(27, 53, 103, 0.47) 0%,
    rgba(27, 53, 103, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(27, 53, 103, 0.47) 0%,
    rgba(27, 53, 103, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(27, 53, 103, 0.47) 0%,
    rgba(27, 53, 103, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b3567",endColorstr="#1b3567",GradientType=1);
  position: absolute;
}

.text-p {
  max-width: 700px;
  margin: auto;
  margin: 80px auto;
  line-height: 1.9em;
  font-size: 18px;
}

.p-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.chart-ctn {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: auto;
  height: 500px;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 100px;
}

.chart-ctn h3 {
  margin-bottom: 30px;
}

.future-ctn {
  margin-top: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.learn-btn {
  clip-path: polygon(100% 0, 100% 65%, 90% 100%, 0 100%, 0 0);
  background-color: #0074c2;
  padding: 2px;
}

.learn-btn div {
  background-color: #000c25;
  padding: 10px 40px;
  clip-path: polygon(100% 0, 100% 65%, 90% 100%, 0 100%, 0 0);
  box-shadow: inset 0 0 13px 2px rgb(0 171 231 / 33%);
}

.learn-btn div:hover {
  background-color: #000c25;
  padding: 10px 40px;
  clip-path: polygon(100% 0, 100% 65%, 90% 100%, 0 100%, 0 0);
  box-shadow: inset 0 0 13px 2px rgb(0 171 231 / 100%);
}

.footnote {
  font-size: 12px;
  opacity: 0.5;
  text-align: center;
  margin-top: -20px;
}

.footnote a {
  text-decoration: underline;
}

.footnote a:hover {
  color: #0074c2;
}

/************************************************ GLITCH *****************************************************************/

:root {
  --f-size: 15;
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #001137;
}

.glitch {
  flex: 1;
  line-height: 0.75;
  margin: auto 10px;
  color: #1af0dc;
  color: #0074c2;
  color: #fff;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;
}
.glitch::before,
.glitch::after {
  --top: 0;
  --left: 0;
  --v-height: 30%;
  --n-tenth: calc(var(--f-size) * 0.1 * var(--top));
  --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
  --b-cut: calc(var(--t-cut) + var(--v-height));
  content: attr(data-text);
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  transform: translateX(calc(var(--left) * 100%));
  filter: drop-shadow(0 0 transparent);
  text-shadow: calc(var(--left) * -3em) 0 0.02em lime,
    calc(var(--left) * -6em) 0 0.02em #ff00e1;
  background-color: var(--bg);
  clip-path: polygon(
    0% var(--t-cut),
    100% var(--t-cut),
    100% var(--b-cut),
    0% var(--b-cut)
  );
}
.glitch::before {
  animation: glitch-b 1.7s infinite alternate-reverse;
}
.glitch::after {
  animation: glitch-a 3.1s infinite alternate;
}
@keyframes glitch-p {
  17% {
    --scale: 0.87;
  }
  31% {
    --scale: 1.1;
  }
  37% {
    --scale: 1;
  }
  47% {
    --scale: 0.91;
  }
  87% {
    --scale: 1;
  }
}
@keyframes glitch-a {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
  }
  20% {
    --left: 0.005;
  }
  40% {
    --left: 0.01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: 0.03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: 0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: 0.083;
    --v-height: 30%;
    --top: 1;
  }
}
@keyframes glitch-b {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
    --top: 10;
  }
  20% {
    --left: -0.005;
  }
  40% {
    --left: -0.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -0.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -0.083;
    --v-height: 30%;
    --top: 1;
  }
}

/*********************************** MEDIA *****************************************/

@media (max-width: 950px) {
  .glitch {
    animation: none;
  }

  .glitch::before {
    animation: none;
  }
  .glitch::after {
    animation: none;
  }

  .glitch::before,
  .glitch::after {
    display: none;
  }

  .block {
    padding: 0 10px;
  }

  h1 {
    font-size: 50px;
  }

  .header {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header img {
    width: 100px;
  }

  .hero {
    display: flex;
    align-items: center;
    margin-top: 80px;
    padding-bottom: 50px;
    flex-direction: column;
  }

  .hero-background {
    background-size: cover;
  }

  .toptext-ctn {
    z-index: 99;
    position: relative;
  }

  .pizza-img {
    text-align: center;
    margin-top: 20px;
  }

  .hero img {
    width: 70%;
    margin: auto;
  }

  .hero h4 {
    font-size: 24px;
  }

  .hero h2 {
    font-size: 35px;
  }

  .hero h2 sup {
    font-size: 25px;
    margin-left: 5px;
  }

  .text-p {
    max-width: 700px;
    margin: auto;
    padding: 0 20px;
    margin: 50px auto;
    line-height: 1.9em;
  }
  .hand {
    display: none;
  }
  .video-ctn,
  video {
    display: none;
  }
}
